var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import styled from '@emotion/styled';
import { Flex } from '../../Flex';
import NextControl from './NextControl';
import PrevControl from './PrevControl';
var ControlsContainer = styled(Flex)(templateObject_1 || (templateObject_1 = __makeTemplateObject([""], [""])));
var Controls = function (_a) {
    var prevDisabled = _a.prevDisabled, nextDisabled = _a.nextDisabled, handlePrevControl = _a.handlePrevControl, handleNextControl = _a.handleNextControl;
    if (prevDisabled && nextDisabled) {
        return null;
    }
    return (React.createElement(ControlsContainer, null,
        React.createElement(PrevControl, { disabled: prevDisabled, handleClick: handlePrevControl }),
        React.createElement(NextControl, { disabled: nextDisabled, handleClick: handleNextControl })));
};
export default Controls;
var templateObject_1;
