var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from '@emotion/styled';
import { backgroundColor, border, borderColor, borderRadius, borderStyle, borderWidth, themeGet, } from 'styled-system';
import { cursor, themeHeight, themeWidth } from '../../../theme/system-utilities';
import { Flex } from '../../Flex';
export var ControlButton = styled(Flex)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n\n  &[disabled] {\n    opacity: 0.5;\n  }\n\n  &:hover:not([aria-disabled='true']) {\n    border: ", " solid ", ";\n\n    svg {\n      stroke: ", ";\n    }\n  }\n\n  &[aria-disabled='true'] {\n    background-color: white;\n    border: ", " solid\n      ", ";\n    display: flex;\n    opacity: 1;\n  }\n"], ["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n\n  &[disabled] {\n    opacity: 0.5;\n  }\n\n  &:hover:not([aria-disabled='true']) {\n    border: ", " solid ", ";\n\n    svg {\n      stroke: ", ";\n    }\n  }\n\n  &[aria-disabled='true'] {\n    background-color: white;\n    border: ", " solid\n      ", ";\n    display: flex;\n    opacity: 1;\n  }\n"])), themeHeight, themeWidth, borderStyle, borderWidth, borderColor, border, borderRadius, backgroundColor, cursor, themeGet('borderWidths.medium'), themeGet('colors.sys.neutral.border.hover'), themeGet('colors.sys.neutral.icon.hover'), themeGet('borderWidths.small'), themeGet('colors.sys.neutral.border.inactive'));
export var controlButtonStyles = {
    as: 'button',
    justifyContent: 'center',
    alignItems: 'center',
    arrowSize: '22px',
    p: 0,
    cursor: 'pointer',
    themeWidth: ['sp_32', null, null, '44px'],
    themeHeight: ['sp_32', null, null, '44px'],
    ml: ['sp_16', null, null, 'sp_24'],
    backgroundColor: 'sys.neutral.background.weak',
    borderColor: 'sys.neutral.border.default',
    borderStyle: 'solid',
    borderWidth: 'small',
    borderRadius: 'circle',
};
var templateObject_1;
