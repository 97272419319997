var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from '@emotion/styled';
import { size } from 'styled-system';
import { stroke, strokeWidth } from '../../../theme/system-utilities';
import Icon from '../../Icon';
var NAVIGATION_ARROW_SIZE = 16;
var ArrowIcon = styled(Icon)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  ", ";\n  ", ";\n  ", ";\n"], ["\n  ", ";\n  ", ";\n  ", ";\n"])), size, stroke, strokeWidth);
export var arrowIconStyles = {
    name: 'arrowRight',
    size: NAVIGATION_ARROW_SIZE,
    strokeWidth: '2px',
};
export default ArrowIcon;
var templateObject_1;
