import { SwipeDirection } from '../constants';
export var getSwipeDistance = function (carouselRef, scrollSlidesAmount) {
    if (scrollSlidesAmount === void 0) { scrollSlidesAmount = 0; }
    if (!carouselRef.current)
        return 0;
    var _a = carouselRef.current, clientWidth = _a.clientWidth, scrollWidth = _a.scrollWidth, children = _a.children, scrollLeft = _a.scrollLeft;
    var slideWithOffset = scrollWidth / children.length || 0;
    var fullyVisibleSlides = Math.floor(clientWidth / slideWithOffset);
    var scrollableSlides = scrollSlidesAmount > 0 ? scrollSlidesAmount : fullyVisibleSlides;
    if (scrollLeft + clientWidth >= scrollWidth) {
        scrollableSlides = Math.max(scrollableSlides - 1, 0);
    }
    var swipeDistance = scrollableSlides * slideWithOffset;
    return Math.ceil(swipeDistance);
};
var getPositionComparator = function (swipeDirection) {
    return swipeDirection === SwipeDirection.Next
        ? function (a, b) { return a >= b; }
        : function (a, b) { return a <= b; };
};
var getChildXStart = function (carouselRef, reverse) {
    if (!carouselRef.current)
        return [];
    var children = carouselRef.current.children;
    var start = 0;
    var parentRect = carouselRef.current.getBoundingClientRect();
    var childXStart = Array.from(children).map(function (child, index) {
        var childRect = child.getBoundingClientRect();
        if (index === 0) {
            start = childRect.left - parentRect.left;
        }
        return childRect.left - parentRect.left - start;
    });
    return reverse ? childXStart.reverse() : childXStart;
};
export var getSwipePosition = function (carouselRef, distance, swipeDirection) {
    var _a;
    if (!carouselRef.current)
        return 0;
    var scrollLeft = carouselRef.current.scrollLeft;
    var childStartPos = getChildXStart(carouselRef, swipeDirection !== SwipeDirection.Next);
    var distanceWithDirection = swipeDirection === SwipeDirection.Next ? distance : distance * -1;
    var swipePosition = scrollLeft + distanceWithDirection;
    // To prevent slide partial visibility when sliding
    var positionComparator = getPositionComparator(swipeDirection);
    return ((_a = childStartPos.find(function (childStart) { return positionComparator(childStart, swipePosition); })) !== null && _a !== void 0 ? _a : swipePosition);
};
export var getActiveSlide = function (carouselRef, scrollLeft) {
    var childStartPos = getChildXStart(carouselRef, true);
    var lastSlideIndex = childStartPos.length - 1;
    return lastSlideIndex - (childStartPos.findIndex(function (childStart) { return childStart <= scrollLeft; }) || 0);
};
export var isScrollable = function (carouselRef, scrollLeft, direction) {
    if (!carouselRef.current)
        return false;
    var _a = carouselRef.current, clientWidth = _a.clientWidth, scrollWidth = _a.scrollWidth;
    if (direction === SwipeDirection.Next) {
        return scrollLeft + clientWidth < scrollWidth;
    }
    return scrollLeft > 0;
};
export var isSwipeable = function (carouselRef, direction) {
    var _a;
    return isScrollable(carouselRef, ((_a = carouselRef.current) === null || _a === void 0 ? void 0 : _a.scrollLeft) || 0, direction);
};
