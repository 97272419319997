var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { useEffect, useState } from 'react';
import debounce from 'lodash/debounce';
import noop from 'lodash/noop';
import { RESIZE_LISTENER_DEBOUNCE, SwipeDirection } from '../constants';
export var useControls = function (handlerRef, scroll) {
    var _a = __read(useState(false), 2), hasNext = _a[0], setHasNext = _a[1];
    var _b = __read(useState(false), 2), hasPrev = _b[0], setHasPrev = _b[1];
    useEffect(function () {
        var handleControls = debounce(function () {
            if (handlerRef.current) {
                var isScrollable = handlerRef.current.isScrollable;
                setHasPrev(isScrollable(scroll, SwipeDirection.Prev));
                setHasNext(isScrollable(scroll, SwipeDirection.Next));
            }
        }, RESIZE_LISTENER_DEBOUNCE);
        handleControls();
        window.addEventListener('resize', handleControls);
        return function () { return window.removeEventListener('resize', handleControls); };
    }, [scroll]);
    if (handlerRef.current) {
        var swipe_1 = handlerRef.current.swipe;
        var swipePrev = function () { return swipe_1(SwipeDirection.Prev); };
        var swipeNext = function () { return swipe_1(SwipeDirection.Next); };
        return { swipePrev: swipePrev, swipeNext: swipeNext, hasPrev: hasPrev, hasNext: hasNext };
    }
    return { swipePrev: noop, swipeNext: noop, hasPrev: false, hasNext: false };
};
