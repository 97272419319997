var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import styled from '@emotion/styled';
import { useLocalization } from '../../../providers/LocaleProvider';
import ArrowIcon, { arrowIconStyles } from './ArrowIcon';
import { ControlButton, controlButtonStyles } from './ControlButton';
var NextControlButton = styled(ControlButton)(templateObject_1 || (templateObject_1 = __makeTemplateObject([""], [""])));
var NextControl = function (_a) {
    var disabled = _a.disabled, handleClick = _a.handleClick;
    var t = useLocalization().t;
    return (React.createElement(NextControlButton, __assign({}, controlButtonStyles, { "aria-disabled": disabled, onClick: handleClick, name: t('h24_carousel_next_item'), "aria-label": t('h24_carousel_next_item'), disabled: disabled }),
        React.createElement(ArrowIcon, __assign({}, arrowIconStyles, { stroke: disabled ? 'sys.neutral.icon.inactive' : 'sys.neutral.icon.default', alt: t('h24_carousel_next_item') }))));
};
export default NextControl;
var templateObject_1;
