var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useRef, useState } from 'react';
import styled from '@emotion/styled';
import { overflow, position } from 'styled-system';
import { flexGap, overflowX, themeHeight } from '../../theme/system-utilities';
import { Flex } from '../Flex';
import { HeadlineH7, SubtitleST4 } from '../Typography';
import Controls from './controls';
import { useCarousel } from './hooks/useCarousel';
import { useControls } from './hooks/useControls';
var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  ", ";\n  ", ";\n"], ["\n  ", ";\n  ", ";\n"])), position, overflow);
var containerStyles = {
    position: 'relative',
    overflow: 'hidden',
};
var Items = styled(Flex)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  ", ";\n  ", ";\n  -webkit-overflow-scrolling: touch;\n  -ms-overflow-style: none;\n  scrollbar-width: none;\n  &::-webkit-scrollbar {\n    display: none;\n  }\n"], ["\n  ", ";\n  ", ";\n  -webkit-overflow-scrolling: touch;\n  -ms-overflow-style: none;\n  scrollbar-width: none;\n  &::-webkit-scrollbar {\n    display: none;\n  }\n"])), overflowX, flexGap);
var getGapStyles = function (slideGap) {
    if (typeof slideGap === 'number') {
        return "".concat(slideGap, "px");
    }
    return slideGap.map(function (gap) { return "".concat(gap, "px"); });
};
var getItemsStyles = function (slideGap) {
    if (slideGap === void 0) { slideGap = 0; }
    return ({
        flexWrap: 'nowrap',
        overflowX: 'auto',
        gap: getGapStyles(slideGap),
    });
};
var SliderHeader = styled(Flex)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  ", ";\n"], ["\n  ", ";\n"])), themeHeight);
var sliderHeaderStyles = {
    alignItems: 'center',
    themeHeight: ['sp_32', null, null, '44px'],
    mb: 'sp_24',
};
var Carousel = function (_a) {
    var children = _a.children, scrollSlidesAmount = _a.scrollSlidesAmount, slideGap = _a.slideGap, afterSlide = _a.afterSlide, title = _a.title, subtitle = _a.subtitle, withTopControls = _a.withTopControls;
    var carouselRef = useRef(null);
    var handlerRef = useRef(null);
    var _b = __read(useState(0), 2), scroll = _b[0], setScroll = _b[1];
    var handleScroll = useCarousel({
        carouselRef: carouselRef,
        handlerRef: handlerRef,
        scrollSlidesAmount: scrollSlidesAmount,
        afterSlide: afterSlide,
        setScroll: setScroll,
    }).handleScroll;
    var _c = useControls(handlerRef, scroll), swipePrev = _c.swipePrev, swipeNext = _c.swipeNext, hasPrev = _c.hasPrev, hasNext = _c.hasNext;
    return (React.createElement(React.Fragment, null,
        withTopControls && (React.createElement(SliderHeader, __assign({ "data-testid": "carousel-header" }, sliderHeaderStyles, { justifyContent: title ? 'space-between' : 'end', width: "100%" }),
            React.createElement(Flex, { flexDirection: "column" },
                title && React.createElement(HeadlineH7, null, title),
                subtitle && React.createElement(SubtitleST4, null, subtitle)),
            React.createElement(Controls, { prevDisabled: !hasPrev, nextDisabled: !hasNext, handlePrevControl: swipePrev, handleNextControl: swipeNext }))),
        React.createElement(Container, __assign({}, containerStyles),
            React.createElement(Items, __assign({}, getItemsStyles(slideGap), { ref: carouselRef, onScroll: handleScroll }), children))));
};
export default Carousel;
var templateObject_1, templateObject_2, templateObject_3;
