import { useEffect, useRef } from 'react';
import debounce from 'lodash/debounce';
import { SCROLL_LISTENER_DEBOUNCE } from '../constants';
import { getActiveSlide, getSwipeDistance, getSwipePosition, isScrollable, isSwipeable, } from '../utils/scroll';
export var useCarousel = function (_a) {
    var handlerRef = _a.handlerRef, carouselRef = _a.carouselRef, scrollSlidesAmount = _a.scrollSlidesAmount, afterSlide = _a.afterSlide, setScroll = _a.setScroll;
    var isSwiping = useRef(false);
    var handleAfterScroll = function (newScrollLeft) {
        if (setScroll) {
            setScroll(newScrollLeft);
        }
        // callback after sliding
        if (afterSlide) {
            afterSlide(getActiveSlide(carouselRef, newScrollLeft));
        }
        isSwiping.current = false;
    };
    var swipe = function (swipeDirection) {
        var swipeable = isSwipeable(carouselRef, swipeDirection);
        if (carouselRef.current && !isSwiping.current && swipeable) {
            isSwiping.current = true;
            var swipeDistance = getSwipeDistance(carouselRef, scrollSlidesAmount);
            var newScrollLeft = getSwipePosition(carouselRef, swipeDistance, swipeDirection);
            carouselRef.current.scrollTo({
                left: newScrollLeft,
                behavior: 'smooth',
            });
        }
    };
    // Set carousel swipe handlers
    useEffect(function () {
        if (handlerRef && carouselRef.current) {
            handlerRef.current = {
                swipe: function (dir) { return swipe(dir); },
                isScrollable: function (scroll, direction) {
                    return isScrollable(carouselRef, scroll, direction);
                },
            };
        }
    }, [carouselRef.current]);
    var handleScroll = debounce(function () {
        var _a;
        handleAfterScroll(Math.ceil(((_a = carouselRef.current) === null || _a === void 0 ? void 0 : _a.scrollLeft) || 0));
    }, SCROLL_LISTENER_DEBOUNCE);
    return { carouselRef: carouselRef, handleScroll: handleScroll };
};
